th td {
    text-align: center;
}
.col-center {
    text-align: center;
}
.row-left td h5 {
    text-align: right;
}
.shipping.desktop h5 {
    text-align:left ;
}
td h5.grantotal {
    
    font-size: 16px;
    font-weight: bold;
}
.align-left, .align-left h5 {
    text-align: right;
}
@media only screen and (max-width: 450px){
    input.input-number {
        width: 62px !important;
        padding-left: 11px !important;
    }
    .subtotal span {
        margin-left: -5px;
    }
    td h5 {
        text-align: left !important;
    }
    td.shipping {
        vertical-align: text-top !important;
    }
    h5.price-number, .product-description {
        font-size: 12px !important;
    }
    th {
        font-size: 12px !important;
        font-weight: bolder !important;
    }
    .desktop {
        visibility: hidden;
        display: none;
    }
    .row-left td h5 {
        margin-left: -25px;
text-align: right !important;       
    }
}