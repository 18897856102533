a.App-link i:hover {
    border-radius: 30%;
    background-color: rgba(210, 121, 120, 0.3);
    cursor: pointer;
}
@media only screen and (max-width:450px) {
    .app-username {
        visibility: hidden;
    }
    .m-show img {
        z-index: -99;
        width: 250px !important;
        margin-top: 65% !important;
       
        overflow: hidden;
    }
    .m-show.img-fix-5d img{ 
        width: 250px !important;
    margin-left: 34% !important;
    }
}
