tr:hover {
    background-color: aliceblue;
    cursor: pointer;
}
.single_product_item {
    border-radius: 5px;
    margin-bottom: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  @media (max-width: 991px) {
    /* line 58, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
    .single_product_item img {
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    /* line 58, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
    .single_product_item img {
      width: 100%;
    }
  }
  
  /* line 66, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item .single_product_text {
    padding: 32px 0;
    background-color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  
  /* line 71, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item .single_product_text h4 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  /* line 77, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item .single_product_text h3 {
    font-weight: 300;
    font-size: 18px;
  }
  
  /* line 82, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item .single_product_text a.add_cart {
    color: #ff3368;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-top: 10px;
  }
  
  /* line 93, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item .single_product_text a i {
    float: right;
    font-size: 18px;
    line-height: 26px;
    color: #000;
  }
  
  /* line 102, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item {
    box-shadow: 0px 10px 20px 0px rgba(0, 23, 51, 0.09);
  }
  
  /* line 105, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item .single_product_text {
    padding: 32px 32px;
  }
  
  /* line 109, ../../01 cl html template/03_jun 2019/188_Aranoz shop_html/sass/_product_list.scss */
  .single_product_item a.add_cart {
    opacity: 1;
    visibility: visible;
  }
.product_details {
    display: grid;
}
.single_product_text ul li {display: table-column-group;}

.single_product_text ul {
    display: grid;
    grid-template-columns: 20% 40% 40%;
}

.single_product_item h3 {
    font-weight: bolder;
}

.single_product_item .single_product_text h3 {
    font-weight: 500;
}


@media (max-width: 576px) {
  .product_top_bar {
      display: flex;
      flex-wrap: wrap;
  }
}