
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #ebf5ff;
    background-image: none;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select */
  .select {
    position: relative;
    display: flex;
    width: 100%;
    height: 3em;
    line-height: 3;
    background: #eff4f8;
    overflow: hidden;
    border-radius: .25em;
    margin: 0 16px 0 16px;
  }
  select {
    flex: 1;
    padding: 0 .5em;
    color: #000;
    cursor: pointer;
  }
  /* Arrow */
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: #c0d6ec;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
  }
  /* Transition */
  .select:hover::after {
    color: #f39c12;
  }
  