.not-container {
    color: #000;
    background: #fff;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-h1 {
    display: inline-block;
    border-right: 1px solid rgba(0, 0, 0, .3);
    margin: 0;
    margin-right: 20px;
    padding: 10px 23px 10px 0;
    font-size: 24px;
    font-weight: 500;
    vertical-align: top;
}
.not-div {
    display:inline-block;text-align:left;line-height:49px;height:49px;vertical-align:middle;
}
.not-div>.not-h2 {
    font-size:14px;font-weight:normal;line-height:inherit;margin:0;padding:0;
}